<template>
    <div>
        <el-submenu v-if="item.children && item.children.length>0" :key="item.id"  :index="indexVal + ''">
            <template slot="title">{{ item.title }}</template>
            <template v-for="(c, i) of item.children">
                <MenuItem :key="i" :item="c" :textColor="text_color" />
            </template>
        </el-submenu>
        <div v-else  >
            <el-menu-item  :key="item.id" :index="indexVal + ''" :class="{'active':item.mark}">
            <span slot="title" class="link-item">
                <router-link :to="item.link || item.path" :style="{'color': text_color}">{{ item.title }}</router-link>
            </span>
            </el-menu-item>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MenuItem',
        props: {
            item: Object,
            textColor: {
                type: String
            },
            indexVal: {
                type: Number
            }
        },
        data () {
            return {
                text_color: '',
                active: '#333333'
            }
        },
        methods: {
            init () {
                this.text_color = this.textColor;
            },
        },
        watch: {
            'textColor': {
                handler () {
                    this.init();
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>
    .el-menu-item :hover {
      color: #409eff !important;
    }
    .active > span > a {
      color: #409eff !important;
    }

    .el-submenu .el-menu-item {
        box-sizing: border-box;
    }
</style>
