<template>
    <transition name="fade" mode="out-in">
        <router-view />
    </transition>
</template>

<script>
export default {
    name: 'KeepAliveRouterView',
    data() {
        return {
            visitedViews: []
        }
    },
    watch: {
        // '$store.state.visitedViews': {
        //     handler(nVal) {
        //         // console.log("[KeepAliveRouterView.vue]nVal:", nVal)
        //         let visitedViews = []
        //         nVal.forEach(e => {
        //             visitedViews.push(...e.matched.map(x => x.name))
        //         });
        //         this.visitedViews = [...new Set(visitedViews)]
        //         // console.log("[KeepAliveRouterView.vue]this.visitedViews:", this.visitedViews)
        //     },
        //     immediate: true,
        //     deep: true
        // }
    }
}
</script>
<style lang="scss" scoped>
    .fade-leave-active,
    .fade-enter-active {
        transition: opacity 0.28s cubic-bezier(0.38, 0, 0.24, 1);
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
