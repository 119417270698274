<template>
    <div class="side-bar">
      <xk-menu
        :logoStatus="false"
        :userRouters="userRouters"
        :background-color="barColor"
        :selBackgroundColor="barSelectedColor"
        :hover-background-color="hoverBackgroundColor"
        :downBackgroundColor="barColor"
        :theme-type="themeType"
        selTextColor="#FFFFFF"
        text-color="#626873"
        :width="navBarWidth"
        :sideBarWd="sideBarWd"
        :staticRouteMenu="staticRouteMenu"
        @navBarFolded="navBarFolded"
      >
      </xk-menu>
    </div>
</template>

<script>
    import MenuItem from './MenuItem';
    export default {
        name: 'SideBar',
        components: {
            MenuItem
        },
        props: {
            barColor: {
                type: String
            },
            headerColor: {
                type: String
            },
            barSelectedColor: {
                type: String
            },
            barPullDownColor: {
                type: String
            },
            hoverBackgroundColor: {
                type: String
            },
            textColor: {
                type: String,
                default: function () {
                    return '#333333'
                }
            },
            user_routers: {
                type: Array
            },
            navBarWidth: {
                type: String
            },
            themeType: {
                type: String
            },
            sideBarWd: {
                type:  String
            },
            staticRouteMenu: {
                type: Array
            }
        },
        data () {
            return {
                bar_color: '',
                header_color: '',
                userRouters: []
            }
        },
        methods: {
            init () {
                this.bar_color = this.barColor;
                this.header_color = this.headerColor;
            },
            getNewUserRouters () {
                this.userRouters = this.user_routers;
            },
            handleOpen (key, keyPath) {

            },
            handleClose (key, keyPath) {

            },
            navBarFolded (wd) {
                this.$emit('navBarFolded', wd)
            }
        },
        watch: {
            'barColor': {
                handler () {
                    this.init();
                },
                deep: true,
                immediate: true,
            },
            'user_routers': {
                handler () {
                    this.getNewUserRouters()
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style>
    .side-bar {
        height: calc(100% - 95px);
        position: fixed;
        top: 75px;
        left: 10px;
        box-sizing: border-box;
        background-color: var(--sild-bar-bc);
        border-radius: 4px;
        overflow: hidden;
        z-index: 2;
    }

    .ul-header {
        padding: 0;
        margin: 0;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #bfcbd9;
    }

    .ul-header li a {
        color: #bfcbd9;
    }



    .el-header {
        background-color: #B3C0D1;
        color: #333;
        line-height: 60px;
    }

    .el-aside {
        color: #333;
    }
</style>
