<template>
    <div
        id="J_pageBoard"
        class="page-board"
        :style="{
            'width': pageBoardWidth,
            'left': navBarWidth
        }"
    >
        <tag-views
            v-show="showCrumb"
            :header-color="headerColor"
            :text-color="textColor"
        >
        </tag-views>

        <link-iframe
            v-if="show"
            :url="linkAdr"
        ></link-iframe>
        <KeepAliveRouterView v-else @setBreadcrumbs="setBreadcrumbs" />
    </div>
</template>

<script>
import TagViews from "./sub/TagViews/Index.vue";
import LinkIframe from "@/components/scrollWrapper/Sub/LinkIframe.vue";
import pageBoard from "@/operations/pageBoard.js";
import { getFrameUrl } from "@/xkUI/libs/menu.js";
import { mapMutations, mapState } from "vuex";

export default {
    name: "PageBoard",
    components: {
        TagViews,
        LinkIframe
    },
    props: {
        headerColor: {
            type: String,
        },
        textColor: {
            type: String,
        },
        navBarWidth: {
            type: String,
        },
        showCrumb: Boolean
    },
    data() {
        return {
            breadcrumb_list: [],
            bread_new_list: [],
            show: false,
            linkAdr: ''
        };
    },
    computed: {
        ...mapState({
            userRouters: (state) => state.userRouters,
            originRouters: (state) => state.originRouters,
        }),
        pageBoardWidth() {
            return `calc(100vw - ${this.navBarWidth})`
        }
    },
    methods: {
        ...mapMutations(['setVisitedViews']),
        init(newVal) {
            this.breadcrumb_list = this.userRouters;
            this.bread_new_list = pageBoard.pageChangeInit(
                this.breadcrumb_list,
                newVal,
            );
            this.isShowCom(newVal)
        },
        isShowCom(routerInfo) {
            console.log(routerInfo,'routerInfo')
            this.show = false
            this.linkAdr = "";
            if (routerInfo.meta.isFrame != 1) {
                return
            }
            if (!routerInfo.meta.frameUrl) {
                this.show = false
                return
            }

            this.show = true
            if (routerInfo.meta.linkJump != 2) {
                return
            }
            let menuMessage = this.originRouters.filter(item => item.menuId === routerInfo.meta.menuId)[0];
            console.log(menuMessage,'[menuMessage]')
            this.show = true
            let linkUrl = getFrameUrl(menuMessage)
            this.linkAdr = linkUrl ? linkUrl : ""
        },
        handlerGlobal() {
            this.$emit('handlerGlobal')
        },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-10-17 16:58:37
         */
        setBreadcrumbs(routerInfo) {
            const routerName = routerInfo.name,
                breadcrumbList = this.$store.state.visitedViews;

            breadcrumbList.map((item, index) => {
                if (item.name === routerName) {
                    breadcrumbList.splice(index, 1);
                }
            })
            this.setVisitedViews(breadcrumbList)
        }
    },
    watch: {
        $route: {
            handler(newVal) {
                this.init(newVal);
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.page-board {
    height: 100%;
    position: fixed;
    top: 75px;
    left: 170px;
    padding-bottom: 0;
    box-sizing: border-box;
    z-index: 2;
    transition: all 0.3s ease;
}
</style>
